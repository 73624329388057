<template>
  <transition name="fade">
    <div id="flashMessage" v-if="GStore.flashMessage">
      {{ GStore.flashMessage }}
      <button @click="GStore.flashMessage = ''" class="btn btn-secondary">Close</button>
    </div>
  </transition>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view />
</template>

<script>
export default {
  inject: ["GStore"]
};
</script>

<style>

.v-enter-from {
  opacity: 0;
}

.v-enter-active {
  transition: opacity 2s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 1s ease-out;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* border-style: solid;
  border-color: #ad1d1d;
  width: 95%;
  margin:auto; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* .home{
  border-style: solid;
  border-color: #42b983;
  width: 80%;
  margin:auto;
} */

.hello {
  /* border-style: solid;
  border-color: #ebd664;
  width: 70%; */
  margin: auto;
}
</style>
