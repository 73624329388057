<template>
  <div class="home">
    <div class="hello card" style="width:24rem;">
      <h5>You are on page {{page}} </h5>
    <div class="card-header">
      Tasks
    </div>
    <TaskCard v-for="task in tasks" :key="task.id" :task="task"/>
    <router-link :to="{name:'TaskList', query: {page: page-1}}" v-if="page != 1"> Prev Page </router-link>
    <router-link :to="{name:'TaskList', query: {page: page+1}}" v-if="hasNextPage"> Next Page </router-link>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TaskCard from '@/components/TaskCard.vue'

//import axios from 'axios'
import TasksService from '@/services/TasksService'
import { watchEffect } from 'vue'

export default {
  name: 'TasksList',
  props: ['page'],
  components: {
    TaskCard,
  },
  data () {
    return {
      tasks: null,
      totalTasks: 0
    }
  },
  computed: {
    hasNextPage (){
      var totalPages = Math.ceil(this.totalTasks/2)
      return this.page < totalPages
    },
    page() {
      return parseInt(this.$route.query.page) || 1
    }
  },
  created(){
    watchEffect(() => {
    console.log('Page is ' + this.page),
    TasksService.getTasks(2, this.page)
      .then(response => {
        this.tasks = response.data,
        this.totalTasks = response.headers['x-total-count']
      })
      .catch(error => {
        console.log('ERRORS' +error)
      })
    })
  }
}
</script>
