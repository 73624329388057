import { createRouter, createWebHistory } from "vue-router";
import TaskList from "../views/TaskList.vue";
import TaskDetails from "../views/task/Details.vue";
import TaskEdit from "../views/task/Edit.vue";
import TaskDelete from "../views/task/Delete.vue";
import TaskLayout from "../views/task/Layout.vue";
import NotFoundComponent from "@/views/NotFoundComponent.vue";

const routes = [
  {
    path: "/:catchAll(.*)",
    component: NotFoundComponent
  },

  {
    path: "/",
    name: "TaskList",
    component: TaskList,
    props: route => ({ page: parseInt(route.query.page) || 1 })
  },
  {
    path: "/task/:id",
    name: "TaskLayout",
    props: true,
    component: TaskLayout,
    children: [
      {
        path: "",
        name: "TaskDetails",
        component: TaskDetails,
      },
      {
        path: "edit",
        name: "TaskEdit",
        component: TaskEdit
      },
      {
        path: "delete",
        name: "TaskDelete",
        component: TaskDelete
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
