<template>
  <ul class="list-group list-group-flush">
    <router-link class="task-link" :to="{name: 'TaskDetails', params:{id: task.id}}">
      <li class="list-group-item"><b>{{task.title}}</b> on the {{task.date}} @ {{task.time}}</li>
    </router-link>
  </ul>
</template>

<script>
import TaskDetails from '@/views/task/Details.vue'
export default {
  name: "TaskCard",
  components: {
    TaskDetails
  },
  props: {
    task: Object
  }
};
</script>
